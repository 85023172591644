<template>
  <div class="btn-group-areatotal">

    <flight-duration-filter />
    <airline-filter />
    <airport-filter />
    <time-filter />
    <budget-filter />

  </div>
</template>

<script>

export default {
  components: {
    FlightDurationFilter: () => import('./filterPanelAtom/flightDurationFilter'),
    AirlineFilter: () => import('./filterPanelAtom/airlineFilter'),
    AirportFilter: () => import('./filterPanelAtom/airportFilter'),
    TimeFilter: () => import('./filterPanelAtom/timeFilter'),
    BudgetFilter: () => import('./filterPanelAtom/budgetFilter'),
  },
};
</script>
